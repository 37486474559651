<template>
  <ag-grid-vue
    class="ag-theme-alpine table-style"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    :checkboxSelection="false"
    @first-data-rendered="onDataRendered"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import baseUrl from "../../../util/backend";

import { toShowAccountingPrice } from "../../../util/accountingPrice";
export default {
  props: {
    jvData: {
      type: Object,
      default: () => {},
    },
    jvTotal: {
      type: Number,
    },
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
        getRowStyle: (params) => {
          if (params.node.footer) {
            return { background: "red" };
          }
        },
      },
      columnDefs: [
        {
          headerName: this.$t("asset.acc_code"),
          field: "acc_code",
          sortable: true,
          width: 200,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.acc_name"),
          field: "acc_name",
          sortable: true,
          width: 200,
        },
        {
          headerName: "Debit",
          field: "debit",
          sortable: true,
          suppressSizeToFit: true,
          cellClass: "text-end",
          cellRenderer: (params) => {
            return params.value || "-";
          },
        },
        {
          headerName: "Credit",
          field: "credit",
          sortable: true,
          suppressSizeToFit: true,
          cellClass: "text-end",
          cellRenderer: (params) => {
            return params.value || "-";
          },
        },
      ],
    };
  },
  computed: {
    rowData() {
      return this.jvData.tangible.map(acc => ({
        acc_code: acc.code_acc,
        acc_name: acc.name_acc,
        debit: toShowAccountingPrice(acc.incoming),
        credit: "-",
      }))
      .concat(this.jvData.tangible.map(acc => ({
        acc_code: acc.code_acc_dp,
        acc_name: acc.name_acc_dp,
        debit: "-",
        credit: toShowAccountingPrice(acc.incoming),
      })))
      .concat(this.jvData.intangible.map(acc => ({
        acc_code: acc.code_acc,
        acc_name: acc.name_acc,
        debit: toShowAccountingPrice(acc.incoming),
        credit: "-",
      })))
      .concat(this.jvData.intangible.map(acc => ({
        acc_code: acc.code_acc_dp,
        acc_name: acc.name_acc_dp,
        debit: "-",
        credit: toShowAccountingPrice(acc.incoming),
      })));
    },
  },
  watch: {
    rowData() {
      this.onDataRendered();
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    onDataRendered() {
      const pinnedBottomData = {
        acc_code: "Total",
        debit: toShowAccountingPrice(this.jvTotal),
        credit: toShowAccountingPrice(this.jvTotal),
      };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);

      this.gridColumnApi.autoSizeAllColumns();
      this.gridApi.sizeColumnsToFit();
    },
  },
  //   beforeMount() {
  //     window.addEventListener("resize", this.onFirstDataRendered);
  //   },
  //   beforeDestroy() {
  //     window.removeEventListener("resize", this.onFirstDataRendered);
  //   },
};
</script>

<style lang="scss" scoped>
.table-style {
  height: 400px;
  width: 100%;
}

* ::v-deep .ag-header-cell-label {
  justify-content: center;
}
* ::v-deep .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}

* ::v-deep .ag-row-pinned {
  background: #ededed;
  font-size: 16px;
}

* ::v-deep .ag-apple-scrollbar {
  &.ag-scrollbar-scrolling {
    display: none !important;
  }
}
</style>
