<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file m-2" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
        <button
          v-if="canUseCustom6"
          class="download-file-outline px-2 m-2"
          @click="exportExcelSunSystems"
        >
          {{ `${$t("btn.dl_file")} (SUN)` }}
        </button>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <select
              class="form-control w-auto me-3 my-1"
              v-model="infoMonth"
              @change="loadJVData"
            >
              <option
                v-for="month in monthList"
                :key="month.value"
                :value="month.value"
                >{{ $t("depreciation.month") }} {{ month.label }}</option
              >
            </select>
            <select
              class="form-control w-auto me-3 my-1"
              v-model="infoYear"
              @change="loadJVData"
            >
              <option v-for="year in yearList" :key="year" :value="year"
                >{{ $t("depreciation.year") }} {{ year }}</option
              >
            </select>
            <Button class="refresh" icon="pi pi-refresh" @click="loadJVData" :loading="isLoading" />
            <select
              class="form-control w-auto ms-3 me-3 my-1"
              v-model="selectedDepartment"
              @change="loadJVData"
            >
              <option :value="null">{{ $t("filter.all") }}</option>
              <option
                v-for="department in allDepartments"
                :key="department.id_location_department"
                :value="department.id_location_department"
                >{{ department.code }} - {{ department.name }}</option
              >
            </select>
          </div>
        </div>
        <JVTable :jvData="jvData" :jvTotal="jvTotal"></JVTable>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
import baseUrl from "../../util/backend";
import { exportUrl } from "../../util/backend.js";
import { authHeader } from "../../store/actions";
import moment from "moment";
import { mapGetters } from "vuex";

import JVTable from "../../components/Table/Depreciation/JVTable.vue";
export default {
  name: "JVDocument",
  components: {
    Loading,
    JVTable,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      infoMonth: moment().format("M"),
      infoYear: new Date().getFullYear(),
      selectedDepartment: null,
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
      jvData: {
        tangible: [],
        intangible: [],
      },
      jvTotal: null,
    };
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
      allDepartments: "alldepartment",
      canUseCustom6: "canUseCustom6",
    }),
    monthList() {
      const list = [];
      if (this.infoYear === +moment().format("YYYY")) {
        const monthNumber = moment().format("M");
        console.log(+monthNumber);
        for (let i = 0; i < monthNumber; i++) {
          list.push({
            label: this.$t("adddate.month_short")[i],
            value: i + 1,
          });
        }
        return list;
      } else {
        return this.$t("adddate.month_short").map((month, i) => {
          return {
            label: month,
            value: i + 1,
          };
        });
      }
    },
    yearList() {
      const currentYear = new Date().getFullYear() - 20;
      const list = [];
      for (let i = 0; i < 21; i++) {
        list.push(currentYear + i);
      }
      return list;
    },
  },
  methods: {
    async exportExcelSunSystems() {
      this.isLoading = true;
      let requestDate;
      const selectedDate = moment()
        .set("date", 1)
        .set("month", this.infoMonth - 1)
        .set("year", this.infoYear)
        .toISOString();
      requestDate = selectedDate;
      try {
        const res = await axios.get(
          `${baseUrl()}depreciation/export/jv/sun/month/${this.infoMonth}/year/${this.infoYear}/${this.company_id}`,
          {
            params: {
              id_location_department: this.selectedDepartment,
              // request_date: requestDate,
            },
            ...authHeader(),
            responseType: "blob",
          }
        );
        const filename = `Sun64 - JV_Document_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsm`;
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel.sheet.macroEnabled.12",
        });
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } catch (err) {
        if (err.response) {
          alert(err.response.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
    async onExport(isMail) {
      this.isLoading = true;
      let requestDate;
      const selectedDate = moment()
        .set("date", 1)
        .set("month", this.infoMonth - 1)
        .set("year", this.infoYear)
        .toISOString();
      if (!moment(selectedDate).isSame(moment(), "month")) {
        requestDate = selectedDate;
      }
      try {
        const filename = `JV_Document_${this.companyName}_${moment().format(
          "DD-MM-YYYY_HH-mm"
        )}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}depreciation/export/jv/month/${this.infoMonth}/year/${this.infoYear}/${this.company_id}`,
          {
            params: {
              id_location_department: this.selectedDepartment,
              // request_date: requestDate,
            },
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async loadJVData() {
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      let requestDate;
      const selectedDate = moment()
        .set("month", this.infoMonth - 1)
        .set("year", this.infoYear)
        .startOf("M")
        .toISOString();
      requestDate = selectedDate;
      // if (!moment(selectedDate).isSame(moment(), "month")) {
      // }
      try {
        const res =  await axios.get(
          `${baseUrl()}depreciation/jv/month/${this.infoMonth}/year/${this.infoYear}/${this.company_id}`,
          {
            params: {
              id_location_department: this.selectedDepartment,
            },
            ...authHeader(),
          }
        );
        this.jvData = {
          tangible: res.data.tangible,
          intangible: res.data.intangible,
        };
        this.jvTotal = res.data.total;
      } catch (err) {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          console.log(err.message);
        }
      }
      this.isLoading = false;
    },
    async loadAllDepartment() {
      await this.$store.dispatch("getalldepartments", {
        id_company: this.company_id,
      });
    },
  },
  async mounted() {
    this.company_id = await this.$store.dispatch("getCompanyIdCookie");
    this.loadJVData();
    this.loadAllDepartment();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>
